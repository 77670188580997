<template>
    <v-row v-if="loading" class="a-list-row ma-0 mb-1 report-loading-row">
        <v-col cols="7" class="pl-2 pr-0 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(25, 75) + '%'"
            />
        </v-col>
        <v-col cols="3" class="pl-2 pr-0 ma-auto">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(35, 55) + '%'"
            />
        </v-col>
        <v-col cols="2" class="pl-2 pr-0 ma-auto d-flex justify-center">
            <v-skeleton-loader
                type="text"
                :width="randomBetween(20, 35) + '%'"
            />
        </v-col>
    </v-row>
    <v-hover v-else v-slot="{ hover }">
        <v-row
            class="a-list-row ma-0 mb-1 report-row"
            :class="{
                white: !hover,
                'concrete-light': hover,
                small: $vuetify.breakpoint.smAndDown
            }"
        >
            <v-col cols="7" class="pl-2 pr-0 ma-auto d-inline-flex">
                <a
                    class="text-truncate"
                    target="_blank"
                    :data-id="mention.id"
                    :href="mention.url"
                    :style="favicon"
                    data-favicon
                >
                    <text-truncated :text="mention.domain" />
                </a>
                <status-chip
                    v-if="isSpecialSite"
                    :status="status"
                    :label="statusLabel"
                    :tooltip="statusTooltip"
                    class="mx-2"
                />
            </v-col>
            <v-col
                cols="3"
                class="pl-2 pr-0 ma-auto d-flex align-center"
                :class="{ 'justify-center': $vuetify.breakpoint.smAndDown }"
            >
                <v-icon small left>{{ moduleTypeIcon }}</v-icon>
                {{ moduleTypeLabel }}
            </v-col>
            <v-col cols="2" class="pl-2 pr-0 ma-auto text-center">
                {{ authority }}
            </v-col>
        </v-row>
    </v-hover>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { StatusChip } from '@/components/StatusChip';
import { TextTruncated } from '@/components/TextTruncated';

import { randomBetween } from '@/utils/helpers';

import type { Distribution, DistributionType } from '@/types/Report';
import { MentionMediaTypes } from '..';

const BrandMentionRowProps = Vue.extend({
    name: 'BrandMentionRow',
    props: {
        mention: {
            type: Object as PropType<Partial<Distribution>>,
            default(): Distribution | null {
                return null;
            }
        },
        distributionType: {
            type: Object as PropType<Partial<DistributionType>>,
            default(): DistributionType | null {
                return null;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        StatusChip,
        TextTruncated
    }
})
export default class BrandMentionRow extends BrandMentionRowProps {
    get site() {
        return (
            this.mention.title ||
            (this.mention?.url ? new URL(this.mention.url).hostname : '')
        );
    }

    get moduleType() {
        return this.mention?.type ? this.mention?.type : '';
    }

    get matchingType() {
        // types can be complex
        return Object.keys(MentionMediaTypes).find(type =>
            type.split(',').includes(this.moduleType)
        );
    }

    get moduleTypeIcon() {
        if (this.moduleType && this.matchingType) {
            return MentionMediaTypes[this.matchingType].icon;
        }

        return MentionMediaTypes.PressReleases.icon;
    }

    get moduleTypeLabel() {
        if (this.$vuetify.breakpoint.smAndDown) {
            return '';
        }

        if (this.moduleType && this.matchingType) {
            return MentionMediaTypes[this.matchingType].label;
        }

        return MentionMediaTypes.PressReleases.label;
    }

    get favicon() {
        const domain =
            this.mention.domain ??
            (this.mention.url ? new URL(this.mention.url).hostname : null);

        if (domain) {
            return `--favicon:url('https://www.google.com/s2/favicons?domain=${domain}')`;
        }

        return '';
    }

    get authority() {
        return this.mention?.authority ?? '-';
    }

    get isSpecialSite() {
        return (
            this.mention.is_crypto ||
            this.mention.is_premium ||
            this.mention.is_external ||
            this.mention.is_financial ||
            this.mention.is_msn
        );
    }

    get status() {
        if (this.isSpecialSite) {
            if (this.mention.is_crypto) {
                return 'crypto';
            }

            if (this.mention.is_financial) {
                return 'financial';
            }

            if (this.mention.is_premium) {
                return 'premium';
            }

            if (this.mention.is_external) {
                return 'external';
            }

            if (this.mention.is_msn) {
                return 'msn';
            }
        }

        return '';
    }

    get statusLabel() {
        if (this.isSpecialSite) {
            if (this.mention.is_crypto && this.distributionType.is_crypto) {
                return 'Crypto';
            }

            if (
                this.mention.is_financial &&
                this.distributionType.is_financial
            ) {
                return 'Financial';
            }

            if (this.mention.is_premium && this.distributionType.is_premium) {
                return 'Premium';
            }

            if (this.mention.is_external) {
                return 'External';
            }

            if (this.mention.is_msn) {
                return 'MSN';
            }
        }

        return '';
    }

    get statusTooltip() {
        if (this.isSpecialSite && this.mention.is_external) {
            return `B.L.O.W. are authored outside AmpiFire, providing a high value backlink to your Amp's promoted URL. This post will not contain your Amp content.`;
        }

        return '';
    }

    randomBetween = randomBetween;
}
</script>

<style lang="scss">
@import '@/components/AList/AList.scss';

.report-loading-row {
    padding-top: 6px;
}

.report-row {
    box-shadow: 0px 5px 10px -5px $grey-medium-light;

    &.small {
        font-size: 80%;
    }

    .status-chip .v-chip__content {
        display: flex;
        justify-content: center;
    }
}

[data-favicon] {
    position: relative;
    padding-left: 24px;

    &::before {
        position: absolute;
        content: '';
        display: block;
        background-image: var(--favicon);
        background-size: 16px 16px;
        width: 16px;
        height: 100%;
        top: calc(50% - 8px);
        left: 0;
    }
}
</style>
